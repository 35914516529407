import { ToggleSwitch } from "../../../components-shared/elements/toggle-switch.js";
import { getCookie, setCookie } from "../../../components-shared/utils/cookie.js";
import { determineTranslation } from "../../utils/language.js";

export class CookieConsentDialog extends HTMLElement {
    constructor() {
        super();

        this.labels = determineTranslation(this.translations);

        this.readAttributes();

        if (this.useDefaultStyles) {
            this.attachShadow({mode: "open"});
            this.container = this.shadowRoot;
        } else {
            this.container = this;
        }
    }

    /* #region Translations */
    private translations = {
        en: {
            title: "You control your own data",
            description: "We want to give you a unique shopping experience, for that we use our own and third-party cookies and other technologies. Some of these are necessary for our web shop to work, others help you get a tailor-made experience. We also do marketing and profiling by exchanging customer information with third parties. By choosing \"Accept all\" you give consent to all purposes. You can also make selections below and choose \"Accept selected\".",
            linkText: `You can read more about our <a href="/ViewPolicyDocument.aspx?docid=CookiePolicy&showaccept=true">use of cookies</a> and <a href="/ViewPolicyDocument.aspx?docid=EndUserPrivacyPolicy&showaccept=true">processing of personal data</a>.`,
            necessary: "Necessary",
            statistical: "Statistical",
            marketing: "Marketing",
            acceptSelected: "Accept selected",
            acceptNecessary: "Accept necessary",
            acceptAll: "Accept all"
        },
        no: {
            title: "Du kontrollerer dine egne data",
            description: "Vi ønsker å gi deg en unik handleopplevelse, for å få til det bruker vi egne og tredjeparts informasjonskapsler/cookies og andre teknologier. Noen av disse er helt nødvendige for at nettbutikken vår skal fungere, andre bidrar til at du skal få en skreddersydd opplevelse. Vi gjør også markedsføring og profilering ved at kundeinformasjon utveksles med tredjeparter. Ved å velge \"Godta alle\" gir du samtykke til alle formål. Du kan også foreta avkrysningsvalg nedenfor og velge \"Godta valgte\".",
            linkText: `Du kan lese mer om v&aring;r <a href="/ViewPolicyDocument.aspx?docid=CookiePolicy&showaccept=true">bruk av cookies</a> og <a href="/ViewPolicyDocument.aspx?docid=EndUserPrivacyPolicy&showaccept=true">behandling av personopplysninger</a>.`,
            necessary: "Nødvendige",
            statistical: "Statistisk",
            marketing: "Markedsføring",
            acceptSelected: "Godta valgte",
            acceptNecessary: "Godta nødvendige",
            acceptAll: "Godta alle"
        },
        sv: {
            title: "Du kontrollerar dina egna data",
            description: "Vi vill ge dig en unik shoppingupplevelse, för att uppnå att vi använder våra egna och tredje parts cookies och andra teknologier. Vissa av dessa är helt nödvändiga för att vår webbutik ska fungera, andra bidrar till att du får en skräddarsydd upplevelse. Vi gör även marknadsföring och profilering genom att utbyta kundinformation med tredje part. Genom att välja \"Acceptera alla\" samtycker du till alla ändamål. Du kan också göra kryssrutor nedan och välja \"Acceptera valda\".",
            linkText: `Du kan läsa mer om vår <a href="/ViewPolicyDocument.aspx?docid=CookiePolicy&showaccept=true">användning av cookies</a> och <a href="/ViewPolicyDocument.aspx?docid=EndUserPrivacyPolicy&showaccept=true">behandling av personuppgifter</a>.`,
            necessary: "Nödvändigt",
            statistical: "Statistisk",
            marketing: "Marknadsföring",
            acceptSelected: "Acceptera valt",
            acceptNecessary: "Acceptera nödvändiga",
            acceptAll: "Acceptera alla"
        },
    };

    private labels: { [key: string]: string };
    /* #endregion */

    /* #region Markup */
    private buildDom() {
        // Styles
        if (this.useDefaultStyles) {
            const link = document.createElement("link");
            link.rel = "stylesheet";
            link.href = import.meta.resolve("./cookie-consent-dialog.css");
            this.container.appendChild(link);
        }

        // Dialog
        this.dialog = document.createElement("dialog");
        this.dialog.id = "cookie-consent-dialog";
        this.dialog.setAttribute("aria-labelledby", "cookie-consent-dialog-title");
        this.dialog.setAttribute("aria-describedby", "cookie-consent-dialog-description");
        this.container.appendChild(this.dialog);

        this.dialog.addEventListener('cancel', (e: Event) => {
            e.preventDefault();
        });

        // Title
        const title = document.createElement("h2");
        title.id = "cookie-consent-dialog-title";
        title.textContent = this.labels.title;
        this.dialog.appendChild(title);

        // Description
        const description = document.createElement("p");
        description.id = "cookie-consent-dialog-description";
        description.textContent = this.labels.description;
        this.dialog.appendChild(description);

        // Link
        const linkCont = document.createElement("p");
        linkCont.id = "cookie-consent-dialog-link";
        linkCont.innerHTML = this.labels.linkText;
        this.dialog.appendChild(linkCont);

        // Consent mode options
        const consentModeOptions = document.createElement("div");
        consentModeOptions.id = "cookie-consent-dialog-consent-mode-options";
        this.dialog.appendChild(consentModeOptions);

        const existingConsents = this.getConsentsFromCookies();

        // Necessary cookies
        const necessaryCookies = document.createElement("div");
        necessaryCookies.id = "cookie-consent-dialog-necessary-cookies";
        necessaryCookies.classList.add("cookie-consent-dialog-mode-option");
        consentModeOptions.appendChild(necessaryCookies);

        const necessaryCookiesText = document.createElement("span");
        necessaryCookiesText.textContent = this.labels.necessary;
        necessaryCookies.appendChild(necessaryCookiesText);

        this.necessaryCookiesToggle = new ToggleSwitch(this.useDefaultStyles);
        this.necessaryCookiesToggle.checked = true;
        this.necessaryCookiesToggle.disabled = true;
        this.necessaryCookiesToggle.addEventListener("change", this.onToggleChange.bind(this));
        necessaryCookies.appendChild(this.necessaryCookiesToggle);

        // Statistical cookies
        const statisticalCookies = document.createElement("div");
        statisticalCookies.id = "cookie-consent-dialog-statistical-cookies";
        statisticalCookies.classList.add("cookie-consent-dialog-mode-option");
        consentModeOptions.appendChild(statisticalCookies);

        const statisticalCookiesText = document.createElement("span");
        statisticalCookiesText.textContent = this.labels.statistical;
        statisticalCookies.appendChild(statisticalCookiesText);

        this.statisticalCookiesToggle = new ToggleSwitch(this.useDefaultStyles);
        this.statisticalCookiesToggle.addEventListener("change", this.onToggleChange.bind(this));
        statisticalCookies.appendChild(this.statisticalCookiesToggle);

        if (existingConsents.statistical) {
            this.statisticalCookiesToggle.checked = true;
        }

        // Marketing cookies
        const marketingCookies = document.createElement("div");
        marketingCookies.id = "cookie-consent-dialog-marketing-cookies";
        marketingCookies.classList.add("cookie-consent-dialog-mode-option");
        consentModeOptions.appendChild(marketingCookies);

        const marketingCookiesText = document.createElement("span");
        marketingCookiesText.textContent = this.labels.marketing;
        marketingCookies.appendChild(marketingCookiesText);

        this.marketingCookiesToggle = new ToggleSwitch(this.useDefaultStyles);
        this.marketingCookiesToggle.addEventListener("change", this.onToggleChange.bind(this));
        marketingCookies.appendChild(this.marketingCookiesToggle);

        if (existingConsents.marketing) {
            this.marketingCookiesToggle.checked = true;
        }

        // Buttons
        const buttons = document.createElement("div");
        buttons.id = "cookie-consent-dialog-buttons";
        this.dialog.appendChild(buttons);

        // Accept selected
        this.acceptSelectedButton = document.createElement("button");
        this.acceptSelectedButton.id = "cookie-consent-dialog-accept-selected";
        this.acceptSelectedButton.textContent = this.labels.acceptNecessary;
        this.acceptSelectedButton.addEventListener("click", this.acceptSelectedClick.bind(this));
        buttons.appendChild(this.acceptSelectedButton);

        // Accept all
        this.acceptAllButton = document.createElement("button");
        this.acceptAllButton.id = "cookie-consent-dialog-accept-all";
        this.acceptAllButton.textContent = this.labels.acceptAll;
        this.acceptAllButton.classList.add("primary");
        this.acceptAllButton.addEventListener("click", this.acceptAllClick.bind(this));
        buttons.appendChild(this.acceptAllButton);

        this.updateAcceptSelectedButtonText();
    }
    /* #endregion */

    /* #region Event handlers */
    private async acceptAllClick(e: MouseEvent) {
        e.preventDefault();
        await this.setConsent(true);
    }

    private async acceptSelectedClick(e: MouseEvent) {
        e.preventDefault();
        await this.setConsent(false);
    }

    private onToggleChange(_: Event) {
        this.updateAcceptSelectedButtonText();
    }
    /* #endregion */

    /* #region Private */
    // Flags
    private useDefaultStyles = true;

    // Elements
    private container: Node;
    private dialog: HTMLDialogElement;
    private acceptSelectedButton: HTMLButtonElement;
    private acceptAllButton: HTMLButtonElement;
    private necessaryCookiesToggle: ToggleSwitch;
    private statisticalCookiesToggle: ToggleSwitch;
    private marketingCookiesToggle: ToggleSwitch;

    private readAttributes() {
        this.useDefaultStyles = 
            !this.hasAttribute("styles") 
            || this.getAttribute("styles").toLowerCase() !== "false";
    }

    private updateAcceptSelectedButtonText() {
        if (this.statisticalCookiesToggle.checked || this.marketingCookiesToggle.checked) {
            this.acceptSelectedButton.textContent = this.labels.acceptSelected;
        } else {
            this.acceptSelectedButton.textContent = this.labels.acceptNecessary;
        }
    }

    private getConsentsFromCookies(): { statistical: boolean, marketing: boolean } {
        const statistical = getCookie("cookie-consent-statistical") === "1";
        const marketing = getCookie("cookie-consent-marketing") === "1";

        return { statistical, marketing };
    }

    private async setConsent(all: boolean) {
        const statistical = all || this.statisticalCookiesToggle.checked;
        const marketing = all || this.marketingCookiesToggle.checked;

        setCookie("cookie-consent-statistical", statistical ? "1" : "0", 365);
        setCookie("cookie-consent-marketing", marketing ? "1" : "0", 365);

        document.dispatchEvent(new Event("cookie-consent-updated", {bubbles: true}));

        this.dialog.close();

        await fetch("/handlers/public/userdata.ashx?a=SetEndUserAgreementsAccepted", { method: "POST" });
    }
    /* #endregion */

    connectedCallback() {
        this.buildDom();
        this.dialog.showModal();
        this.acceptAllButton.focus();
    }
}

customElements.define("cookie-consent-dialog", CookieConsentDialog);